jQuery( document ).ready(function($) {

  // mobile menu
  $('#menu-open').click(() => $( '.menu-wrapper' ).addClass( 'active' ) )
  $('#menu-close').click(() => $( '.menu-wrapper' ).removeClass( 'active' ) )

  //infinite scroll
  let loading = false
  let page = 1
  const isLoading = () => loading = true
  const notLoading = () => loading = false
  const nextPage = () => page++
  const updateContainer = () => $('.recent-news-wrapper').addClass(`page-${page}`)
  const stopScrollListener = () => $(window).unbind('scroll')
  const test = () => console.log(`Reached bottom ${page}`)

  function checkPosition () {
    let isBottom = $(window).scrollTop() >= $(document).height() - $(window).height() - 1
    if( isBottom ){
      isLoading()
      stopScrollListener()
      nextPage()
      updateContainer()
      test()
      setTimeout(notLoading, 1000)
      setTimeout(scrollListener, 2000)
    }
  }

  function scrollListener() {
    if( !loading ){
      $(window).on("scroll", function() {
        checkPosition()
      });
    }
  }  

  const swiper = new Swiper('.hero-slider', {
    speed: 1000,
    autoplay: {
      delay: 2000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  });

  
  scrollListener()

});